<template>
  <div class="g_page_box" style="background: #F7F7F7;">
    <ul class="tab">
      <li @click="tab_change(i)" v-for="i in tab_list" :key="i.type">
        {{i.name}}
        <div v-if="active === i.type" class="line"/>
      </li>
    </ul>
    <div class="g_main_content order" >
      <div class="no-data" v-if="list.length===0&&!loading">
        <img width="185" src="../../assets/img/order/no-date.png"/>
        暂无订单信息
      </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" v-else>
        <van-list
          :offset="100"
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
        <div v-for="(item,index) in list" :key="index" class="order-i">
          <div>
             <span class="order-state">
              <span :class="['state',{'check-state-cancel': item.order_state===5}]">{{item.order_state_label}}</span>
              <span v-if="item.order_state<5" :class="['check-state',{'check-state-1':item.lab_report_state === 4},{'check-state-2':item.lab_report_state === 1}]">{{item.lab_report_state_label}}</span>
            </span>
            <div class="i-head">
              <img width="22" height="22" v-if="item.order_type === 3" src="../../assets/img/order/medicine.png"/>
              <img width="22" height="22" v-else src="../../assets/img/order/luohu.png"/>
              <span class="name">{{item.shop_title}}</span>
            </div>
            <div class="i-content">
              <template v-if="item.order_type !== 3">
                <div :class="['i-type',{'i-check': item.order_type === 1}]">{{item.order_type === 1 ? '检验' : '检查'}}</div>
                <div style="flex: 1;">{{item.order_title&&item.order_title.join('、')}}</div>
              </template>
              <template v-else>
                <div class="shangpin"><img width="22" height="22" src="../../assets/img/order/luohu.png"/></div>
                <div class="shangpin"><img width="22" height="22" src="../../assets/img/order/luohu.png"/></div>
              </template>
            </div>
            <div class="order-time" v-if="item.order_state === 4 && item.order_type===2">
              <img width="16" height="16" src="../../assets/img/order/order-time.png"/>
              请预约检查时间
            </div>
            <div class="patient" v-if="item.order_type !== 3">
              <span class="patient-name">{{item.patient_name}} {{item.patient_age}}岁</span>
              {{item.lab_report_state === 1 ? '待预约' : format_date(item.reservation_time,'yyyy年MM月dd日')}}
            </div>
          </div>
          <div class="line"></div>
          <div class="i-info">
            <div :class="['pay-way',{'pay-way-inner': item.payment_type === 1}]"><img width="11" height="11" style="margin-right: 3px;" src="../../assets/img/order/pay.png"/> {{item.payment_type === 1 ? '院内收款'  : '院外收款'}}</div>
            <div v-if="hander_num(item.items).num" style="padding-right: 5px;">共{{hander_num(item.items).num}}件</div> 实付 <span class="unit">¥</span><span>{{format_money(hander_num(item.items).price)}}</span>
          </div>

          <div class='i-foot'>
            <div class="i-button i-cancel" v-if="item.order_state ===1" @click="cancel_order(item)">取消订单</div>
            <div class="i-button" v-if="item.order_state === 1" @click="to_pay(item)">去支付</div>
            <div class="i-button" v-if="item.order_state ===3  && item.order_type === 3">确认收货</div>
            <div class="i-button" v-if="item.order_state === 4 && item.order_type === 3">再来一单</div>
            <div class="i-button i-cancel" v-if="(item.order_state === 4 && item.order_type!==3) || (item.order_state === 3&&item.lab_report_state===2)" @click="to_card(item)">健康卡</div>
            <div class="i-button" v-if="(item.order_state === 4 && item.order_type!==3) || (item.order_state === 3&&item.lab_report_state===2)" @click="look_detail(item)">查看详情</div>
            <div class="i-button" v-if="item.order_state === 3  && item.order_type===2 && item.lab_report_state===1" @click="look_detail(item)">预约</div>
          </div>
        </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import {get_order_list,confirm_cancel_order} from './service'
import {format_date,format_money} from '../../utils/format'
import { Dialog } from 'vant'

export default {
  data () {
    return {
      tab_list:[{type: 0,name:'全部'},{type: 1,name:'待付款'},{type: 2,name:'待发货'},{type: 3,name:'待收货'},{type: 4,name:'已完成'},{type: 5,name:'已取消'}],
      active: 0,
      list: [],
      current_list: [],
      refreshing: false,
      finished:false,
      loading: false,
      timer: null,
      page_size: 5,
      page: 0,
      total: 0,
      error: false
    }
  },
  created() {
    const {order_state} = this.$route.query
    if(order_state) this.active = +order_state
    this.search_order_list()
  },
  methods: {
    format_date,
    format_money,
    hander_num(items) {
      let num = 0
      let price = 0
      for(let i of items) {
        num += +i.count
        price += +i.price*+i.count
      }
      return {
        num,
        price
      }
    },
    tab_change(i) {
      this.active = i.type
      this.current_list = []
      this.list = []
      this.page = 0
      this.total = 0
      this.search_order_list()
    },
    onLoad() {
      if(this.timer) clearTimeout(this.timer)
      if (this.refreshing) {
        this.loading = true
        this.list = []
        this.current_list = []
        this.page = -1
        this.refreshing = false
      }
      if(this.list.length>0&&this.list.length === this.total || this.error || this.total === 0) {
        this.finished = true
        this.error = false
        this.loading = false
        return
      }
      this.timer = setTimeout(()=>{
        this.page = this.page + 1
        this.search_order_list()
      },1000)
    },
    onRefresh() {
      this.finished = false
      this.loading = false
      this.onLoad()
    },
    async search_order_list () {
      const {active} = this
      let {page,page_size} = this
      let params = {page,page_size,order_state:active}
      if(!params.order_state) delete params.order_state
      try {
        this.loading = true
        const {data} = await get_order_list(params)
        this.current_list = data.data
        this.total = data.total_elements || 0
        this.list = this.list.concat(data.data)
      } catch(e) {
        this.error = true
        console.log(e)
      }finally {
        this.loading =  false
      }
    },
    cancel_order(item) {
      Dialog.alert({
        message: '是否取消订单？',
        showCancelButton: true,
        confirmButtonColor: 'rgba(0, 136, 255, 1)'
      }).then(() => {
        this.confirm_cancel_order(item.id)
      })
    },
    to_pay(val) {
      this.$router.push({
        path: '/pre-order',
        query: {
          id: val.group_id,
        }
      })
    },
    to_card() {
      // this.$router.push({
      //   path: '/card',
      //   query: {}
      // })
    },
    look_detail(val) {
      this.$router.push({
        path: '/my-check-detail',
        query: {
          order_id: val.id,
          lab_report_state: val.lab_report_state,
        }
      })
    },
    order_detail(val) {
      this.$router.push({
        path: '/order-detail',
        query: {
          order_id: val.id
        }
      })
    },
    async confirm_cancel_order(id) {
      try {
        this.loading = true
        await confirm_cancel_order(id)
        this.refreshing = true
        this. onLoad()
      } finally {
        this.loading =  false
      }
    }
  }
}
</script>
<style scoped>
.order {
  background: #F7F7F7;
  padding-top: 15px;
}
.tab {
  display: flex;
  height: 50px;
  background:#fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #565656;

  & >li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    & .line {
      border: 2px solid #0088FF;
      margin-top: 4px;
      position: absolute;
      width: 40px;
      bottom: 9px;
    }
  }
}
.order-i {
  margin-bottom: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  min-height: 191px;
  padding: 21px 16px 15px;
  display: flex;
  flex-direction: column;
  & .order-state {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #242938;
    & .state {
      flex: 1;
      &.check-state-cancel {
        color:rgba(154, 154, 154, 1);
      }
    }
    & .check-state {
      color:rgba(0, 136, 255, 1);

      &.check-state-1 {
        color:rgba(0, 196, 60, 1);
      }
      &.check-state-2 {
        color:rgba(255, 68, 68, 1);
      }
    }
  }
  & .i-head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0088FF;
    & .name{
      flex: 1;
      padding-left: 9px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #3D3D3D;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
    }
  }
  & .i-content {
    flex: 1;
    display: flex;
    padding-top: 9px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #3D3D3D;
    & >img {
      margin-right: 5px;
    }
    & .shangpin {
      width: 56px;
      height: 56px;
      background: RGBA(243, 241, 242, 1);
      border-radius: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
    & .i-type {
      width: 34px;
      height: 17px;
      background: #00C43C;
      text-align: center;
      line-height: 17px;
      border-radius: 4px;
      font-size: 11px;
      font-weight: 400;
      color: #fff;
      margin:0 6px 0 30px;
      &.i-check {
        background: #0088FF;
      }
    }

  }
  & .order-time{
    height: 31px;
    background: #FEEDED;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #DF6363;
    padding-left: 7px;
    display: flex;
    align-items: center;
    margin:14px 0px 14px 30px;
    & img {
      margin-right: 5px;
    }
  }
  & .patient {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #707070;
    padding: 25px 0 0 30px;
    & .patient-name {
      flex:1;
    }
  }
  & .line {
    border-top: 1px solid  rgba(112, 112, 112, 0.07);
    margin-top: 20px;
    margin-bottom: 14px;
  }
  & .i-info{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    line-height: 17px;
    color: #3D3D3D;
    padding: 0px 0 10px;

    & .pay-way {
      background: #55D844;
      display: flex;
      align-items: center;
      border-radius: 2px;
      padding: 2px 4px;
      font-size: 7px;
      line-height: 10px;
      color: #FFFFFF;
      margin-right: 6px;
      &.pay-way-inner {
        background: #FFD800;
      }
    }
    & >span {
      font-size: 19px;
      font-weight: 500;
      color: #3D3D3D;
      font-size: 19px;
      line-height: 23px;
    }
    & .unit {
      font-size: 14px;
      line-height: 14px;
      padding-left: 6px;
    }
  }
  & .i-foot {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .i-button {
      width: 74px;
      height: 28px;
      background: #0088FF;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content:center;
      font-size: 12px;
      font-weight: 400;
      line-height: 7px;
      color: #FFFFFF;
      margin-left: 9px;
      &.i-cancel {
        background: #FFFFFF;
        border: 1px solid #B5B5B5;
        color: #4E4E4E;
      }
    }
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color:rgba(112, 112, 112, 0.5);
  }
}

</style>
